import logo from './logo.svg';
import './App.css';
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import logo1 from "./assets/spooder1.png";
import logo2 from "./assets/spooder2png.png";
import test1 from "./assets/test.jpg";
import { loadStripe } from '@stripe/stripe-js';

// import Contact from "./components/Contact";
import Pricing from "./components/Pricing";
import Contact from './components/Contact';
import Services from './components/Services';
import Company from './components/Company';
import Footer from './components/Footer';

const navigation = [
  { name: 'Home', href: '#homeid' },
  { name: 'Services', href: '#serviceid' },
  { name: 'Pricing', href: '#pricingid' },
  { name: 'Company', href: '#companyid' },
  { name: 'Contact', href: '#contactid' },
]

const PUBLIC_KEY = "pk_test_51NSUgiKViE6T3LULsJcO7m2AXDaZq5eWdbJlkHenBUKnf0yCQOUUElSNJ0c1wzD1CKaE82fL1UIQ7DdaLcVW4VvU00Lpq7UEwC"
const stripeTestPromise = loadStripe(PUBLIC_KEY)

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className='font-digi'>
      <div className="bg-violet-700">
        <header className="absolute inset-x-0 top-0 z-50" id='homeid'>
          <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-10 w-auto"
                  src={logo1}
                  alt=""
                />
              </a>
              <h1 className='text-white mt-2 ml-1'>Webbit</h1>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-zinc-950 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src={logo1}
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-white"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-white/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-white"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
        <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-zinc-950 shadow-xl shadow-indigo-600/10 ring-4 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
          <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
                Your gateway to digital engagement.
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-white">
                  At Webbit, we specialize in crafting exceptional custom websites tailored to suit your unique business needs. Our team of talented designers and developers is committed to delivering stunning and functional web solutions that leave a lasting impression.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="#pricingid"
                    className="rounded-md bg-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started
                  </a>
                  <a href="#companyid" className="text-sm font-semibold leading-6 text-white">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <img
                src={test1}
                alt=""
                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <Services></Services>

      {/* Pricing */}
      <Pricing></Pricing>

      {/* COMPANY */}
      <Company></Company>

      {/* CONTACT */}
      <Contact></Contact>

      {/* FOOTER */}
      <Footer></Footer>

    </div>
  );
}

export default App;
