import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

const features = [
    {
        name: 'Curated Website.',
        description:
            'Our objective is to create a custom website that fulfills your requirements, embodies your brand, captivates your target audience, and provides a seamless and enjoyable user experience. We achieve this by fostering effective communication and actively involving you in the decision-making process.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Photoshoot.',
        description: "Our aim is to align the photoshoot with your brand and objectives. We strive to capture visually stunning images that elevate your website's visual appeal, and effectively convey your message.",
        icon: LockClosedIcon,
    },
    {
        name: 'Web Hosting.',
        description: 'We take responsibility for selecting, configuring, and managing the web hosting infrastructure to ensure that your websites are secure, accessible, and perform optimally on the internet.',
        icon: ServerIcon,
    },
]

function Services() {

    return (
        <div className="overflow-hidden bg-[#0a0a0c] py-24 sm:py-32">
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8" id='serviceid'>
                <br></br>
                <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                    <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-violet-700">Services</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">A better workflow</p>
                            <p className="mt-6 text-lg leading-8 text-white">

                                Our mission is to empower businesses and individuals by crafting exceptional custom websites that reflect their unique visions and goals.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-white">
                                We are dedicated to providing personalized, responsive, and intuitive websites that elevate our clients' online presence, boost their credibility, and maximize their digital success.
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-white lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-white">
                                            <feature.icon className="absolute left-1 top-1 h-5 w-5 text-violet-700" aria-hidden="true" />
                                            {feature.name}
                                        </dt>{' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <div className="sm:px-6 lg:px-0">
                        <div className="relative isolate overflow-hidden bg-violet-700 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                            <div
                                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-200 opacity-20 ring-1 ring-inset ring-white"
                                aria-hidden="true"
                            />
                            <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                                <img
                                    src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                                    alt="Product screenshot"
                                    width={2432}
                                    height={1442}
                                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-white ring-1 ring-white/10"
                                />
                            </div>
                            <div
                                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;