import { useState } from 'react'
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { RadioGroup } from '@headlessui/react'

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

function Pricing() {
    
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    
    const [frequency, setFrequency] = useState(frequencies[0])

    const tiers = [
        {
            name: 'Project',
            id: 1,
            href: '#',
            abb:'/p_',
            upfront: '$299.99',
            price: { monthly: '$9.99', annually: '$119.88' },
            description: 'The essentials to provide your best work for clients.',
            features: [
                '1-2 page website',
                'Basic analytics',
                '48-hour support response time',
                'Free revision every 3 months'
            ],
            mostPopular: false,
        },
        {
            name: 'Startup',
            id: 2,
            href: '#',
            abb:'/s_',
            upfront: '$749.99',
            price: { monthly: '$19.99', annually: '$239.88' },
            description: 'A plan that scales with your rapidly growing business.',
            features: [
                '3-5 page website',
                '1 hour photoshoot',
                'Advanced analytics',
                '24-hour support response time',
                'Free revision every 2 months',
            ],
            mostPopular: true,
        },
        {
            name: 'Enterprise',
            id: 3,
            href: '#',
            abb:'/e_',
            upfront: '$1499.99',
            price: { monthly: '$29.99', annually: '$359.88' },
            description: 'Dedicated support and infrastructure for your company.',
            features: [
                '7-10 page website',
                '3 hour photoshoot',
                'Advanced analytics',
                '12-hour, dedicated support response time',
                'Free revision every month',
            ],
            mostPopular: false,
        },
    ]

    // function sendToServer() {
    //     fetch("http://localhost:4000/processpayment", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //             items: [
    //                 { id: 1, quantity: 1 },
    //             ],
    //         }),
    //     })
    //         .then(res => {
    //             if (res.ok) return res.json()
    //             return res.json().then(json => Promise.reject(json))
    //         })
    //         .then(({ url }) => {
    //             console.log(url)
    //             // window.location = url
    //         })
    //         .catch(e => {
    //             console.error(e.error)
    //         })
    // }

    return (
        <div className="bg-zinc-950 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8" id='pricingid'>
                <br></br>
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-violet-700">Pricing</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        Pricing plans for teams of&nbsp;all&nbsp;sizes
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                    Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer
                    loyalty, and driving sales.
                </p>
                <div className="mt-16 flex justify-center">
                    <RadioGroup
                        value={frequency}
                        onChange={setFrequency}
                        className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
                    >
                        <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                        {frequencies.map((option) => (
                            <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    classNames(checked ? 'text-violet-600' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                                }
                            >
                                <span>{option.label}</span>
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {tiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular ? 'bg-white/5 ring-2 ring-violet-600' : 'ring-1 ring-white/10',
                                'rounded-3xl p-8 xl:p-10'
                            )}
                        >
                            <div className="flex items-center justify-between gap-x-4">
                                <h3 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                                    {tier.name}
                                </h3>
                                {tier.mostPopular ? (
                                    <p className="rounded-full bg-violet-600 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                                        Most popular
                                    </p>
                                ) : null}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                            <p className='mt-4 text-4xl font-bold tracking-tight text-white'>{tier.upfront}</p>
                            <p className="flex items-baseline gap-x-1">
                                <span className="mt-1 text-sm leading-6 text-gray-300">{tier.price[frequency.value]}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                            </p>
                            <button
                                buttonria-describedby={tier.id}
                                onClick={() => {
                                    fetch("https://webbit-server-d975a4a671bc.herokuapp.com/", {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        items: [
                                          { id: tier.id },
                                        ],
                                      }),
                                    })
                                      .then(res => {
                                        if (res.ok) return res.json()
                                        return res.json().then(json => Promise.reject(json))
                                      })
                                      .then(({ url }) => {
                                        window.location = url
                                      })
                                      .catch(e => {
                                        console.error(e.error)
                                      })
                                  }}
                                className={classNames(
                                    tier.mostPopular
                                        ? 'bg-violet-600 text-white shadow-sm hover:bg-violet-500 focus-visible:outline-indigo-500'
                                        : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                )}
                            >
                                Buy plan
                            </button>
                            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pricing;